import { 
  Button,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid, 
  TextField
} from "@mui/material"
import { ChangeEvent, useCallback } from "react"
import { WeekReaderModalProps } from "../../interfaces/main.interfaces"

const WeekReaderModal = ({
  weekModalOpen, 
  handleWeekModalClose,
  datesInWeek,
  datesInWeekWithGratitude,
  setDatesInWeekWithGratitude
} : WeekReaderModalProps) => {

  const handleMultiLineChange = useCallback((
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    weekDate: string
  ) => {
    setDatesInWeekWithGratitude({
      ...datesInWeekWithGratitude,
      [weekDate]: e.target.value
    })
  }, [datesInWeekWithGratitude, setDatesInWeekWithGratitude]);

  const submitWeekData = () => {
    console.log(datesInWeekWithGratitude)
  }

  return (
    <Dialog open={weekModalOpen} onClose={handleWeekModalClose} fullWidth>
      <DialogTitle>Your Gratitude For This Week</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {datesInWeek?.map(weekDate => (
            <Grid item xs={12}>
              <TextField 
                variant='filled'
                label={weekDate}
                value={
                  datesInWeekWithGratitude?.hasOwnProperty(weekDate) 
                    ? datesInWeekWithGratitude[weekDate] 
                    : ""
                }
                onChange={(e) => {handleMultiLineChange(e, weekDate)}}
                minRows={4}
                multiline
                fullWidth 
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleWeekModalClose} autoFocus>
          Cancel
        </Button>
        <Button variant="contained" onClick={submitWeekData}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WeekReaderModal;