import { WeekSquareProps } from "../../interfaces/main.interfaces"
import { Square } from "./main-style";

const WeekSquare = ({
  weekNum,
  selectedWeekNum, 
  handleWeekModalOpen, 
  color, 
  setSelectedWeekNum 
} : WeekSquareProps) => {
  return (
    <Square 
      color={ color }
      selected={ weekNum === selectedWeekNum }
      key={weekNum}
      onClick={() => {
        setSelectedWeekNum(weekNum);
        handleWeekModalOpen();
      }} 
    />
  )
}

export default WeekSquare;