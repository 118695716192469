import moment from "moment";
import { memo, useMemo } from "react";
import { SquareGridProps } from "../../interfaces/main.interfaces";
import { getWeeksBetweenDates } from "../../utils";
import { SquareGridContainer } from "./main-style";
import WeekSquare from "./WeekSquare";

const SquareGrid = ({selectedWeekNum, setSelectedWeekNum, handleWeekModalOpen, birthDate} : SquareGridProps) => {
  const totalWeeksTo100Years = 5217;
  const todayDate = moment().format('MM/DD/YYYY');

  const getPastWeeks = getWeeksBetweenDates(birthDate, todayDate);
  const currentWeek = getPastWeeks + 1;

  const decideWeekColor = (weekNum : number) => {
    if (weekNum === currentWeek) {
      return "#AFE1AF";
    } 

    if (weekNum <= getPastWeeks) {
      return "#89CFF0";
    }

    return "#DCDCDC";
  }
  
  const allWeekSquares = useMemo( () => Array(totalWeeksTo100Years - 1)
    .fill(0)
    .map((_, weekNum) => (
    <WeekSquare 
      key={weekNum + 1}
      weekNum={weekNum + 1}
      setSelectedWeekNum={setSelectedWeekNum}
      handleWeekModalOpen={handleWeekModalOpen}
      color={decideWeekColor(weekNum + 1)}
      selectedWeekNum={selectedWeekNum}
    />
  )), [selectedWeekNum, setSelectedWeekNum]);

  return <SquareGridContainer>{allWeekSquares}</SquareGridContainer>;
}

export default memo(SquareGrid);