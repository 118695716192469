import styled from "styled-components";

export const SquareGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Square = styled.div<ColorProps>`
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
  border : ${props => props.selected ? '2px solid #8b0000': '1px solid black'};
  margin : 1px;
  box-sizing : border-box;
`;

export const MainReaderContainer = styled.div`
  flex : 1;
  min-height: 50vh;
  height: 100%;
  padding : 1rem;
  font-family: 'Roboto', sans-serif;
`;

export const MainTextArea = styled.textarea`
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
`;

export const SaveButton = styled.input`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px;
  text-align: right;
  text-decoration: none;
  margin-top: 1rem;
  float: right;
`;

interface ColorProps {
  color: string;
  selected?: boolean;
}