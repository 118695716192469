import { Button, Grid, TextField, Typography } from "@mui/material";import { useState } from "react";
;

const MainReader = () => {
  const [todayGratitude, setTodayGratitude] = useState("");
  const submitTodayGratitude = () => {
    console.log(todayGratitude);
  }
  return (
    <div style={{marginTop: "1rem", marginBottom: "1rem"}}>
      <Typography variant="h2" gutterBottom>
        What are you Grateful For Today?
      </Typography> 
      <TextField 
        variant="filled"
        value={todayGratitude}
        onChange={(e) => {setTodayGratitude(e.target.value)}} 
        minRows={4} 
        multiline 
        fullWidth 
      />
      <Grid container alignItems="center" justifyContent="flex-end" style={{marginTop: "1rem"}}>
        <Button variant="contained" onClick={submitTodayGratitude}>
          Submit
        </Button>
      </Grid>      
    </div>
  )
}

export default MainReader;