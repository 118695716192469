import { 
  Container,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MainReader from './components/main/MainReader';
import WeekReaderModal from './components/main/WeekReaderModal';
import SquareGrid from './components/main/WeekSquareGrid';
import { getWeekDates } from './utils';

function App() {
  const [selectedWeekNum, setSelectedWeekNum] = useState(0);
  const [weekModalOpen, setWeekModalOpen] = useState(false);
  const [birthDate] = useState("03/21/2001");
  const [datesInWeekWithGratitude, setDatesInWeekWithGratitude] = useState<Record<string, string>>({});
  const [datesInWeek, setDatesInWeek] = useState<string[]>([]);

  const handleWeekModalClose = () => {
    setWeekModalOpen(false);
  }

  const handleWeekModalOpen = () => {
    setWeekModalOpen(true);
  }

  useEffect(() => {
    setDatesInWeek(
      getWeekDates(birthDate, selectedWeekNum)
    );

    setDatesInWeekWithGratitude({
      
    })
  }, [birthDate, selectedWeekNum])

  return (
    <Container>
      <MainReader/>
      <SquareGrid 
        selectedWeekNum={selectedWeekNum}
        setSelectedWeekNum={setSelectedWeekNum}
        handleWeekModalOpen={handleWeekModalOpen}
        birthDate={ birthDate }
      />
      <WeekReaderModal 
        weekModalOpen={weekModalOpen}
        handleWeekModalClose={handleWeekModalClose}
        datesInWeek={datesInWeek}
        datesInWeekWithGratitude={datesInWeekWithGratitude}
        setDatesInWeekWithGratitude={setDatesInWeekWithGratitude}
      />
    </Container>
  );
}

export default App;
