import moment from "moment";

export function getWeekDates(startDate : string, numWeeks : number) {
  const start = moment(startDate, 'MM/DD/YYYY');
  const dates = [start];

  for (let i = 1; i < 7; i++) {
    const date = moment(start).add(i, 'days');
    dates.push(date);
  }

  for (let i = 1; i < numWeeks; i++) {
    for (let j = 0; j < 7; j++) {
      const date = moment(dates[j]).add(7, 'days');
      dates[j] = date;
    }
  }
  return dates.map(date => date.format('MM/DD/YYYY'));
}

export function getWeeksBetweenDates(startDate : string, endDate : string) {
  return moment(endDate, 'MM/DD/YYYY').diff(moment(startDate, 'MM/DD/YYYY'), 'weeks');
}